<template>
    <div class="container">
      <page-title :title="`Add Bank`" />
  
      <div class="row justify-content-cente">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <form @submit.prevent="save">
                <div class="container">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Bank Name</label>
                        <input
                          required
                          type="text"
                          class="form-control"
                          v-model="bank.name"
                          placeholder="Bank Name"
                        />
                      </div>
                    </div>
                 
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Bank Email</label>
                        <input
                          required
                          type="email"
                          class="form-control"
                          v-model="bank.email_address"
                          placeholder="Bank Email"
                        />
                      </div>
                    </div>
                    
                    <div class="col-md-12">
                      <div class="form-group">
                        <button class="btn btn-secondary float-right">
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    mounted() {
      this.fetch()
    },
  
    data() {
      return {
        bank: {
          name: "",
            email_address: "",
        },
      };
    },
  
    methods: {
      fetch() {
        this.$loader.start()
        this.$axios.get(`/api/v1/dashboard/banks/${this.$route.params.id}`).then(response => {
          this.bank = response.data
          this.$loader.stop()
        })
      },
  
      save() {
        this.$loader.start()
  
        let data = {
          name: this.bank.name,
          email_address: this.bank.email_address
        }
  
        this.$axios
          .put(`/api/v1/dashboard/banks/${this.bank.id}`, data)
          .then(() => {
            this.$loader.stop()
            this.$router.push({ name: "banks.show" });
          })
          .catch((error) => {
            this.$loader.stop()
            this.$alert.httpError(error.response);
          });
      },
    },
  };
  </script>
  